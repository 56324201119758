/* general styles */
/* eslint-disable */
import './general/scss/index.scss';


/* stuff and polyfills */

import './general/js/lazysizes';


/* components */

import './components/lazysizes';


/* legacy */

import $ from 'jquery';

import 'bootstrap';


import './legacy/js/vendors/jquery.unobtrusive-ajax.min.js';

import './legacy/js/vendors/jquery.responsive-tables.min.js';

import './legacy/js/vendors/jquery.cookie.min.js';

import 'tether';

import 'popper.js';

import './legacy/js/vendors/slick.min.js';

// import './legacy/js/vendors/datepicker.min.js';

import './legacy/js/vendors/_/modernizr-custom.js';

import './legacy/js/vendors/_/jquery.alphanum.min.js';

import './legacy/js/vendors/_/jquery.icheck.min.js';

import './legacy/js/vendors/_/bootstrap-inputmask.js';

import './legacy/js/vendors/_/lightgallery.min.js';

import './legacy/js/vendors/_/lg-thumbnail.min.js';


import './legacy/js/_/membership.js';
import './legacy/js/_/passStrength';
import './legacy/js/_/site-global-variables.js';
import './legacy/js/_/site-functions.js';
import './legacy/js/_/site.js';

import './legacy/js/app';

import './legacy/js/modules/carousel';
import './legacy/js/modules/functions';
import './legacy/js/modules/sliders';
import './legacy/js/modules/tabs';


import articleSearchInit from './modern/search/articleSearch';
import SidebarDropwnCta from './components/sidebar/js/SidebarDropdownCta';
import CitySelect from './modern/citySelect/citySelect';
/* require svg */

const files = require.context('./general/svg', true, /^\.\/.*\.svg/);

files.keys().forEach(files);

articleSearchInit();
SidebarDropwnCta();
CitySelect();
window.unipolSharedNamespace.gtmTracking();
