export default function articleSearchInit() {
    const input = document.querySelector('.js-search-input');
    const link = document.querySelector('.js-search');
    let term = '';
    if (!input && !link) return;
    input.onchange = (e) => {
        term = e.currentTarget.value;
    };
    input.onkeyup = (e) => {
        if (e.key === 'Enter') {
            _redirect(term);
        }
    };
    link.onclick = (e) => {
        e.preventDefault();
        _redirect(term);
    };
}

function _redirect(term) {
    const url = new URL(window.location.href);
    url.searchParams.set('term', term);
    window.location = url;
}
