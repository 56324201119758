export default function CitySelect() {
    const selector = document.querySelector('.js-dropdown-city');
    if (!selector) return;
    selector.onchange = (e) => {
        const selectedOption = selector.options[selector.selectedIndex];
        const optionText = selectedOption.innerText;
        fetch('/my-unipol/setselectedcity/' + optionText).finally(() => {
            const decodedUri = decodeURI(selector.value);
            window.location = decodedUri;
        });
    };
}
