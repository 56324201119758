export default function SidebarDropwnCta() {
    const sidebarCTAList = document.querySelectorAll('.js-sidebar-cta');

    if (!sidebarCTAList.length) return;

    sidebarCTAList.forEach((item) => {
        const submitBtn = item.querySelector('.js-sidebar-cta-submit');
        const selectNode = item.querySelector('.js-sidebar-cta-select');
        const warning = item.querySelector('.js-sidebar-cta-warning');
        let selectedValue = '';
        if (!selectNode && !submitBtn && !warning) return;

        submitBtn.onclick = () => {
            // eslint-disable-next-line no-unused-expressions
            selectedValue === '' ?
                warning.classList.remove('sidebar-panel__warning--hide') :
                (window.location = selectedValue);
        };
        selectNode.onchange = (e) => {
            selectedValue = e.target.value;
            // eslint-disable-next-line no-unused-expressions
            selectedValue === '' ?
                warning.classList.remove('sidebar-panel__warning--hide') :
                warning.classList.add('sidebar-panel__warning--hide');
        };
    });
}
